var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "total-wrap"
  }, [_c('div', {
    staticClass: "node-container"
  }, [_c('div', {
    staticClass: "node-container-left"
  }, [_c('div', {
    staticClass: "tag-container"
  }, [_c('div', {
    staticClass: "node-tag-container"
  }, [_c('div', {
    staticClass: "node-tag",
    style: {
      borderColor: _vm.isNewest ? '#3875F6' : '#dcdcdc'
    }
  })])]), _c('div', {
    staticClass: "line-container"
  }, [!_vm.isFirst ? _c('div', {
    staticClass: "line",
    staticStyle: {
      "height": "75px"
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "node-container-right"
  }, [_c('div', {
    staticClass: "node-title",
    style: {
      color: _vm.isNewest ? '#222' : '#999'
    }
  }, [_vm._v(_vm._s(_vm.nodeData.opeTitle))]), _c('div', {
    staticClass: "node-desc",
    style: {
      color: _vm.isNewest ? '#4b4b4b' : '#999'
    }
  }, [_vm._v(" " + _vm._s(_vm.acceptStationFixed) + " "), _vm.nodeData.phone && _vm.nodeData.phone != -1 ? _c('span', {
    staticClass: "node-phone",
    on: {
      "click": function click($event) {
        return _vm.callPhone(_vm.nodeData.phone);
      }
    }
  }, [_vm._v(_vm._s(_vm.nodeData.phone))]) : _vm._e()]), _c('div', {
    staticClass: "node-time"
  }, [_vm._v(_vm._s(_vm.nodeData.opeTime))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };