import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default {
  props: {
    isNewest: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isMainNode: {
      type: Boolean,
      default: false
    },
    nodeData: {
      type: Object,
      default: function _default() {
        return {
          status: '',
          statusName: '',
          createTime: '2019-01-01 00:00:00',
          acceptStation: 'xxxxxx'
        };
      }
    }
  },
  computed: {
    nodeIconUrl: function nodeIconUrl() {
      if (this.nodeData.status === 'WATTING_PAY') {
        // 待付款
        return this.isNewest ? '/static/images/ic-order-commit.png' : '/static/images/ic-order-commit-G.png';
      } else if (this.nodeData.status === 'PAYED') {
        // 待发货
        return this.isNewest ? '/static/images/ic-paied.png' : '/static/images/ic-paied-G.png';
      } else if (this.nodeData.status === 'WATTING_DELIVER') {
        // 已揽件
        return this.isNewest ? '/static/images/ic-pacakaging.png' : '/static/images/ic-pacakaging-G.png';
      } else if (this.nodeData.status === 'DELIVERING') {
        // 运输中
        return this.isNewest ? '/static/images/ic-sending.png' : '/static/images/ic-sending-G.png';
      } else if (this.nodeData.status === 'COMPLETE') {
        // 已完成
        return this.isNewest ? '/static/images/ic-delivering.png' : '/static/images/ic-delivering-G.png';
      }
    },
    acceptStationFixed: function acceptStationFixed() {
      if (!this.nodeData.opeRemark) return '';
      return this.nodeData.opeRemark.replace(/(\d{3})\d{4}(\d{4})/, '');
    }
  },
  methods: {
    callPhone: function callPhone(phone) {
      // 创建输入框元素
      var oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = phone;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$buefy.toast.open("已复制电话号码");
      // 复制后移除输入框
      oInput.remove();
    }
  }
};